import React, {useContext, useState, useEffect} from "react";
import {ThemeContext} from "../Contexts/ThemeContext";
import {Title} from "../Title";
import {GameContext} from "../Contexts/GameContext";
import './Categories.css'

//testing single line export
export const Categories = () => {

    const url = "https://scategories.herokuapp.com/categories";
    const {theme} = useContext(ThemeContext);
    const {gameState} = useContext(GameContext);
    const [categories, setCategories] = useState([]);

    const dummyList = [
        {id:'d1', label:'?'},
        {id:'d2', label:'?'},
        {id:'d3', label:'?'},
        {id:'d4', label:'?'},
        {id:'d5', label:'?'},
        {id:'d6', label:'?'},
        {id:'d7', label:'?'},
        {id:'d8', label:'?'},
        {id:'d9', label:'?'},
        {id:'d10', label:'?'},
        {id:'d11', label:'?'},
        {id:'d12', label:'?'}
    ];

    useEffect(() => {

        const getCategories = async() => {

            const response = await fetch(url);
            const data = await  response.json();

            if(data.categories){

                setCategories(data.categories);

            }else{

                console.log('Error retrieving categories');
            }
        };

        if(gameState){

            getCategories();
        }

        return () => setCategories([]);

    }, [gameState]);

    const renderList = (categoriesList) => {

        return categoriesList.map((item) =>
            <li key={item.id}>{gameState?item.label:'?'}</li>
        );
    }

    return (
        <div className={'categoryList'} style={{...theme, display:'flex'}}>
            <Title title={'Categories'} />
            <div className={'wrapper'}>
                <ol className={'list'}>
                    {gameState&&categories?renderList(categories):renderList(dummyList)}
                </ol>
            </div>
        </div>
    )
}
