import React, {useEffect, useRef, useState} from 'react';
import './App.css';
import Timer, {defaultTimeLimit} from "../Timer";
import Letter from "../Letter";
import {ThemeContext, themes} from "../Contexts/ThemeContext";
import {Categories} from "../Categories/Categories";
import {Play} from "../Play";
import {GameContext} from "../Contexts/GameContext";
import {SideBar} from "../SideBar/SideBar";

function App() {

    const url = "https://scategories.herokuapp.com";
    const [timeLimit] = useState(defaultTimeLimit);
    const [theme, setTheme] = useState(themes.dark);
    const [timer, setTime] = useState(timeLimit);
    const [gameState, setGameState] = useState(false);
    let firstRun = useRef(true);

    const toggleTheme = () => {

        switch (theme.background) {
            case themes.dark.background:
                setTheme(themes.blue);
                break;
            case themes.blue.background:
                setTheme(themes.light);
                break;
            default:
                setTheme(themes.dark);
                break;
        }
    }

    useEffect(() => {

        if(firstRun.current){
            const warmUp = async() => {

                // Warm up heroku dynamo as it takes 5 seconds to spool up if its been idle for more that 30 mins
                await fetch(url);
            }

            warmUp();
            firstRun.current = false;
        }

        if(timer === 0 && gameState){

            setTimeout(() => {

                alert('Times Up!');
                setGameState(false);
            }, 10);

        }
    }, [timer, gameState, setGameState]);

    return (
        <div className="App" style={{backgroundColor:theme.background}}>
            <ThemeContext.Provider value={{theme, toggleTheme}}>
                <GameContext.Provider value={{gameState, setGameState}} >
                    <Categories />
                    <Letter />
                    <Timer timer={timer} setTime={setTime} />
                    <Play />
                    <SideBar />
                </GameContext.Provider>
            </ThemeContext.Provider>
        </div>
    );
}

export default App;
