import React from "react";

export const defaults = {
    fontSize:'3rem',
    textAlign:'center',
    borderRadius:'5px',
    display: 'grid',
    justifyItems: 'center',
    alignItems: 'center',
    padding: '1em 0.5em 0.5em',
    position: 'relative',
    borderStyle:'ridge',
    borderWidth:'3px'
}
export const themes = {
    dark: {
        color: 'white',
        background: 'black',
        borderColor:'white',
        ...defaults
    },
    light: {
        color: 'black',
        background: 'white',
        borderColor:'black',
        ...defaults
    },
    blue: {
        color: 'white',
        background: '#0055AA',
        borderColor:'white',
        ...defaults
    }
};

export const ThemeContext = React.createContext(
    {
        theme: themes.dark,
        updateTheme: () => {}
    }
)
