import React, {useContext} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {ThemeContext} from "./Contexts/ThemeContext";
import {Title} from "./Title";
import {GameContext} from "./Contexts/GameContext";
import {faPlay, faStop} from "@fortawesome/free-solid-svg-icons";

export const Play = () => {

    const {theme} = useContext(ThemeContext);
    const {gameState, setGameState} = useContext(GameContext);

    const toggle = () => {

        setGameState(!gameState);
    }

    return (
      <div style={theme} onClick={toggle}>
          <Title title={'Game'} />
          <span style={{textTransform:'uppercase'}}>
              {gameState ?
                <FontAwesomeIcon icon={faStop}/>:
                <FontAwesomeIcon icon={faPlay} />}

          </span>
      </div>
    );
}
