import React, {useContext} from "react";
import './SideBar.css';
import {ThemeContext} from "../Contexts/ThemeContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faInfoCircle, faPalette} from "@fortawesome/free-solid-svg-icons";


export const SideBar = () => {

    const {theme, toggleTheme} = useContext(ThemeContext);
    const style = {
        borderWidth:'0',
        fontSize:'1.5rem',
        padding:'0'
    };


    return (
        <div className={'side-bar'} style={{...theme, ...style}}>
            <FontAwesomeIcon onClick={toggleTheme} icon={faPalette} style={{alignSelf:'start'}}></FontAwesomeIcon>
            <FontAwesomeIcon icon={faInfoCircle} style={{alignSelf:'end'}}></FontAwesomeIcon>
        </div>
    );
};
