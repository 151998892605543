import React, {useContext, useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit, faQuestionCircle} from '@fortawesome/free-regular-svg-icons'
import {ThemeContext} from "./Contexts/ThemeContext";

export const Title = ({title, btn = null}) => {

    const { theme } = useContext(ThemeContext);

    const style = {
        borderBottomColor: theme.borderColor,
        borderBottomStyle:'solid',
        borderBottomWidth:'1px',
        color: theme.color,
        position:'absolute',
        top:'0',
        left:'0',
        width:'95%',
        padding:'4px 2.5%',
        fontSize:'1rem',
        textAlign:'left',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr'
    };

    const getIcon = () => {

        if(btn.iconName === 'edit'){

            return faEdit;
        }else{

            return faQuestionCircle;
        }
    }

    useEffect(() => {

    }, [])

    return (
        <div style={style}>
            <span style={{justifySelf:'start'}}>{title}</span>
            {btn ?
                <FontAwesomeIcon onClick={btn.action} style={{justifySelf:'end'}} icon={getIcon()} />
                : <span></span>
            }
        </div>
    );
}
