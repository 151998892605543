import React, {useCallback, useContext, useEffect, useState} from "react";
import {ThemeContext} from "./Contexts/ThemeContext";
import {Title} from "./Title";
import {GameContext} from "./Contexts/GameContext";

const Letter = () => {

    const {theme} = useContext(ThemeContext);
    const { gameState } = useContext(GameContext);
    const [currentLetter, setLetter] = useState('');

    const letters = "ABCDEFGHIJKLMNOPRSTUVW";

    const generateLetter = useCallback(() => {

        setLetter(letters.charAt(Math.floor(Math.random() * letters.length)));
    }, [setLetter]);

    useEffect(() => {

        if(gameState){

            generateLetter();
        }

        return () => {setLetter('')};

    }, [generateLetter, gameState]);

    return (
        <div style={theme} onClick={generateLetter}>
            <Title title={'Letter'} />
            {currentLetter && gameState ? currentLetter:'?'}
        </div>
    );
}

export default Letter;
