import React, {useContext, useEffect, useState} from "react";
import {ThemeContext} from "./Contexts/ThemeContext";
import {Title} from "./Title";
import {GameContext} from "./Contexts/GameContext";

export const defaultTimeLimit = 240;

const Timer = ({timer, setTime}) => {

    const { theme } = useContext(ThemeContext);
    const { gameState, setGameState } = useContext(GameContext);

    const [timeLimit, setLimit] = useState(defaultTimeLimit);

    useEffect(() => {

        if(!gameState){

            setTime(timeLimit);

        }else{

            const id = setTimeout(() => {

                if(timer > 0 && gameState) {
                    const time = timer - 1;
                    setTime(time);
                }
            }, 1000);

            return () => {
                clearTimeout(id);
            };
        }

    }, [timer,gameState, setTime, setGameState, timeLimit]);

    const changeLimit = () => {

        const limit = prompt("Set new time limit");
        if(limit !== null) {

            setGameState(false);
            if (!isNaN(parseInt(limit))) {

                setLimit(parseInt(limit));
            }

        }
    }

    return (<div style={{...theme,minWidth:'2em'}}>
        <Title title={'Timer'} btn={{iconName:'edit', action:changeLimit}} />
        {timer}
    </div>);
};

export default Timer;
